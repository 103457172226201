<template>
  <div class="m-card" style="height:285px;">
    <div class="mc-top">
      <div class="mc-top-title">
        <span class="mc-top-txt s-bold s-primary">供应信息</span>
      </div>
      <div class="mc-top-itembox">
        <div @click="$store.commit('setDemandType','供应产品');$router.push(`/home/demand/${exhibitionId}`)" class="mc-tip-item">更多</div>
      </div>
    </div>
    <template v-if="demandList && demandList.length">
      <swiper class="swiper_wrap" :options="swiperOption" ref="demandSwiper">
        <swiper-slide v-for="(items, ind) in demandList" :key="ind">
          <div :key="'item' + index" class="m-list" v-for="(item, index) in items">
            <div class="ml-item" @click="linkToDemandDetail({ id: item.id, isFollow: item.isFollow })">
              <div class="ml-item-l">{{ item.productName }}</div>
              <div class="ml-item-r" v-if="item.createAt">
                <span class="ml-item-txt">{{ item.createAt }}</span>
              </div>
            </div>
          </div>
        </swiper-slide>
      </swiper>

      <!-- <div :key="'item' + index" class="m-list" v-for="(item, index) in demandList">
      </div> -->
    </template>
    <!-- <div class="u-empty" v-else>暂无数据</div> -->
    <div class="u-empty" v-if="loading">数据加载中...</div>
    <div class="u-empty" v-else-if="!loading && !demandList.length">暂无数据</div>
    <needsModel ref="needsModel"></needsModel>
    <Supplyqrcode ref="showcode"></Supplyqrcode>
  </div>
</template>

<script>
import needsModel from "@components/needsModel";
import Supplyqrcode from "@components/Supplyqrcode/index.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "demand-module",
  components: {
    needsModel,
    Supplyqrcode,
  },
  data () {
    return {
      demandList: [], // 供需列表
      demandPageIndex: 1,
      demandPageSize: 15,
      swiperOption: {
        // some swiper options/callbacks
        // 所有的参数同 swiper 官方 api 参数
        // ...
        direction: 'vertical',
        spaceBetween: 0,
        loop: false,
        autoplay: {
          delay: 4000,
        },
        // pagination: {
        //   clickable: true,
        // },
      },
      loading: true, // 数据加载中
    };
  },
  computed: {
    ...mapGetters({
      exhibitionId: "getExhibitionId",
      exhibitionInfo: "getExhibitionInfo",
    }),
    demandOption () {
      let query = `
        query($query:QueryInput!){
          supplyDemandQuery{
            query(query:$query){
                hasNextPage
                pageIndex
                totalCount
                items {
                  content
                  productName
                  imgUrls
                  memberId 
                  createAt
                  publisherType
                  viewCount
                  updateAt
                  type
                  langVersion
                  commentCount
                  id
                  auditTime
                  member{
                    id
                    avatarUrl
                    company
                    jobTitle
                    name
                  }
                }
              }
            }
        }
      `;
      let where = {
        Equal: {
          n: "exhibitionId",
          v: this.exhibitionId,
        },
        Equal1: {
          n: "auditState",
          v: 1,
        },
        Equal_langVersion: {
          n: 'langVersion',
          v: 'zh-cn'
        },
        Equal2: {
          n: "type",
          v: "供应产品",
        },
        Equal_isdeleted: {
          n: "isDeleted",
          v: false,
        },
      };
      let order = [{ N: "updateAt", v: 0 }];
      let opt = {
        query: query,
        variables: {
          query: {
            page: this.demandPageIndex,
            size: this.demandPageSize,
            where: JSON.stringify(where),
            order: JSON.stringify(order),
          },
        },
      };
      return opt;
    },
  },
  created () {
    this.demandInit(); // 供需列表初始化
  },
  methods: {
    ...mapActions({
      graphqlPost: "graphqlPostByZXS",
      getShareConfig: "share/getShareConfig",
      LinkAct: "linkTo/LinkAct",
      linkToNewsDetails: "linkTo/linkToNewsDetails",
      linkToRegSteps: "linkTo/linkToRegSteps",
      linkToProductDetais: "linkTo/linkToProductDetais",
      linkToDemandDetail: "linkTo/linkToDemandDetail",
    }),
    goQrCode (id) {
      if (id) {
        this.$refs.showcode.open(id);
      }
    },
    async demandInit () {
      this.loading = true;
      // 供需列表初始化
      let data = await this.graphqlPost(this.demandOption);
      this.demandTotalCount = data.data.supplyDemandQuery.query.totalCount;
      let list = data.data.supplyDemandQuery.query.items;
      let listArr = [];
      if (list && list.length) {
        list.forEach((c, i) => {
          if (c.imgUrls) {
            c.imgUrls = c.imgUrls.split(";");
          }
          c.createAt = moment(c.createAt).format("YYYY/MM/DD");
          let ii = Math.floor(i / 5);
          if (!listArr[ii]) {
            listArr[ii] = [];
          }
          c.ind = i + 1;
          listArr[ii].push(c);
        });
        this.demandList = listArr;
        this.$emit("setCount", this.demandTotalCount);
      } else {
        this.demandList = [];
      }
      this.loading = false;
    },
    changeDemand () {
      // 供需 循环换一组
      if (this.demandPageIndex * this.demandPageSize > this.demandTotalCount) {
        this.demandPageIndex = 1;
      } else {
        this.demandPageIndex += 1;
      }
      this.demandInit();
    },
  },
};
</script>

<style lang="scss" scoped>
.swiper_wrap {
  height: calc(100% - 40px);
  overflow: hidden;
}
</style>
