<template>
  <div class="m-card s-2" :class="{ 's-3': exhibitionId === '6d9aa6f0-aa0b-4523-9b16-6ee8307b628c' }" v-if="visitList && visitList.length && userViewShow">
    <div class="mc-top">
      <div class="mc-top-title">
        <span class="mc-top-txt s-bold s-primary">
          访客
          <template v-if="exhibitionInfo && exhibitionInfo.userCount">（共{{ exhibitionInfo.userCount }}人）</template>
        </span>
      </div>
    </div>
    <div class="m-imglist" v-if="visitList && visitList.length">
      <div :key="'m-imglist1-v-' + index" class="m-imglist-item s-6" style="width:14.3%" v-for="(item, index) in visitList">
        <div class="imgbox">
          <img :src="item.userInfo.avatarUrl" v-if="item.userInfo && item.userInfo.avatarUrl" />
          <img src="@/assets/img/user.png" v-else />
        </div>
      </div>
    </div>
    <div class="u-empty" v-else>暂无数据</div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "visit-module",
  data () {
    return {
      visitList: [], // 访客列表
      visitCount: 0,
    };
  },
  computed: {
    ...mapGetters({
      exhibitionConfig: "getExhibitionConfig",
      exhibitionInfo: "getExhibitionInfo",
    }),
    visitOption () {
      let query = `
        query($query:_Any!){
          exhibitionUserQuery {
            page(
              query: $query
            ) {
              totalCount
              items {
                userInfo
              }
            }
          }
        }
      `;
      let where = {
        equal: {
          n: "exhibitionId",
          v: this.exhibitionId,
        },
        // notEqual_userInfo: { n: "userInfo" },
        // notEqual_userInfo2: { n: "userInfo", v: "" },
      };
      let opt = {
        query: query,
        variables: {
          query: {
            where: where,
            order: [{ n: "updateAt", v: 0 }],
            page: 1,
            size: 12,
          },
        },
      };
      return opt;
    },
    exhibitionId () {
      return this.$route.params.id || "";
    },
    // 判断是否显示访客人数
    userViewShow () {
      return this.exhibitionConfig && this.exhibitionConfig.display
        ? this.exhibitionConfig.display.userViewShow
        : false;
    },
  },
  created () {
    this.visitInit(); // 访客初始化
  },
  methods: {
    ...mapActions({
      graphqlPost: "graphqlPostByZXS",
      getShareConfig: "share/getShareConfig",
    }),
    async visitInit () {
      let data = await this.graphqlPost(this.visitOption);
      let res = JSON.parse(JSON.stringify(data.data.exhibitionUserQuery));
      this.visitCount = res.page.totalCount;
      if (res.page && res.page.items.length) {
        let arr = [];
        res.page.items.map((c) => {
          if (c.userInfo) {
            c.userInfo = JSON.parse(c.userInfo);
            arr.push(c);
          } else {
            arr.push({});
          }
        });
        this.visitList = arr;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
