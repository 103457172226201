<template>
  <div class="m-card" v-if="contactList && contactList.length">
    <div class="mc-top">
      <div class="mc-top-title">
        <span class="mc-top-txt s-bold s-primary">联系我们</span>
      </div>
    </div>
    <div v-if="contactList && contactList.length">
      <div :key="'contact-' + index" class="home-info" v-for="(item, index) in contactList">
        <div class="hi-l">
          <p>
            <span class="txt1">{{ item.fullName }}</span>
            <span style="font-size: 12px; color: #8c8c8c">{{ item.title }}</span>
          </p>
          <p v-if="item.phoneNumber">手机：{{ item.phoneNumber }}</p>
          <p v-if="item.qQId">
            QQ：
            <a :href="'http://wpa.qq.com/msgrd?v=3&uin=' + item.qQId + '&site=qq&menu=yes'" target="_blank">{{ item.qQId }}</a>
          </p>
          <p v-if="item.email">邮箱：{{ item.email }}</p>
        </div>
        <div class="hi-r" v-if="item.weChatQrCode">
          <img :src="item.weChatQrCode" />
        </div>
      </div>
    </div>
    <div class="u-empty" v-else>暂无数据</div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "contact-module",
  data() {
    return {
      contactList: [], // 展会联系人列表
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      exhibitionId: "getExhibitionId",
      exhibitionInfo: "getExhibitionInfo",
    }),
    contactOption() {
      let query = `
        query($where: String!) {
          customerServiceQuery {
            getList(where: $where) {
              fullName
              qQId
              phoneNumber
              weChatId
              weChatQrCode
              title
              email
            }
          }
        }
      `;
      let where = {
        equal_isDeleted: {
          n: "isDeleted",
          v: 0,
        },
        equal_exhibitionId: {
          n: "exhibitionId",
          v: this.exhibitionId,
        },
      };
      let opt = {
        query: query,
        variables: {
          where: JSON.stringify(where),
        },
      };
      return opt;
    },
  },
  created() {
    this.contactInit(); // 联系人初始化
  },
  methods: {
    ...mapActions({
      graphqlPost: "graphqlPostByZXS",
    }),
    async contactInit() {
      let data = await this.graphqlPost(this.contactOption);
      let res = JSON.parse(JSON.stringify(data.data.customerServiceQuery));
      if (res && res.getList.length) {
        this.contactList = res.getList;
      }
      console.log("contactList", this.contactList);
    },
  },
};
</script>

<style lang="scss" scoped>
.home-info {
  padding: 20px;
  display: flex;
  .hi {
    &-l {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      p {
        width: 100%;
        font-size: 14px;
        color: #666;
        &:first-child {
          margin-bottom: 10px;
        }
        .txt1 {
          color: #333;
          margin-right: 5px;
        }
        .txt2 {
          font-size: 12px;
        }
      }
    }
    &-r {
      width: 76px;
      height: 76px;
      margin: auto;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
