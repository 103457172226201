<template>
  <div class="m-card pro_wrap s-2" v-if="(productList && productList.length) || userProduct.length > 1" :class="{ 's-3': exhibitionId === '6d9aa6f0-aa0b-4523-9b16-6ee8307b628c' }">
    <div class="mc-top">
      <div class="mc-top-title">
        <span class="mc-top-txt s-bold s-primary">产品推荐</span>
      </div>
      <div class="m-list">
        <div class="m-list-l" v-if="userProduct && userProduct.length > 1">
          <div @click="setProductActive('all')" class="m-list-item" :class="{ 's-primary': productActive === 'all' }">热门</div>
          <div
            :class="{ 's-primary': productActive === item.name }"
            :key="'userPro-' + index"
            :title="item"
            @click="setProductActive(item.name)"
            class="m-list-item"
            style="max-width: 130px"
            v-for="(item, index) in userProduct"
          >
            {{ item.name }}
          </div>
        </div>
        <div @click="$router.push(`/home/product/${exhibitionId}`)" class="m-list-r">更多</div>
      </div>
    </div>
    <div class="m-list2" style="padding: 20px 10px">
      <Row v-if="productList && productList.length">
        <Col :key="'m-list222-1' + index" class="m-list2-box" span="6" v-for="(item, index) in productList">
          <div @click="linkToProductDetais(item.id)" class="ml2-item" style="padding: 0 10px">
            <div class="ml2-item-box">
              <template v-if="item.video && JSON.parse(item.video).cover && JSON.parse(item.video).cover != 1">
                <img :src="JSON.parse(item.video).cover" v-if="JSON.parse(item.video).cover" />
                <img src="@/assets/img/empty.png" v-else />
              </template>
              <template v-else>
                <img :src="item.banner" v-if="item.banner" v-load="'oPro'" />
                <img src="@/assets/img/empty.png" v-else />
              </template>
              <div class="rebang" v-if="item.isPopularize">
                <div style="display: flex;align-items: center;justify-content: center;">
                  <i class="iconfont icon-fire" style="font-size: 14px; margin-right: 2px"></i>
                  热榜
                </div>
              </div>
              <div class="is_threeD" v-if="item.view3DUrl">
                <i class="iconfont icon-d" style="font-size: 12px;margin-left: 6px;margin-right: 4px;color: #ff5c00;"></i>
                <span style="font-size: 12px;color: #ff5c00;display: inline-block;">3D</span>
              </div>
              <div class="qj_div" v-if="item.panoramaUrl">
                <i class="iconfont icon-yuanxitubiao-"></i>
              </div>
              <div class="play_video" v-if="item.video && JSON.parse(item.video).url">
                <i class="iconfont icon-shipinmianxing"></i>
              </div>
            </div>
          </div>
          <div class="ml2-item-info" style="height: 71px; padding: 11px 10px 18px">
            <p>{{ item.name }}</p>
            <p v-if="item.exhibitor && item.exhibitor.company">{{ item.exhibitor.company.name }}</p>
          </div>
        </Col>
      </Row>
      <div class="u-empty" v-else>暂无数据</div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "topic-product",
  data() {
    return {
      page: 1, // 选中
      size: 8, // 选中
      userProduct: [], // 产品类别
      productActive: "all", // 选中
      productList: [], // 为你推荐展品 列表
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      exhibitionId: "getExhibitionId",
      exhibitionInfo: "getExhibitionInfo",
    }),
    cateOption() {
      let categoriesGql = `
        query($hostId:Guid!){
          categories(hostId:$hostId){
            childrens{
              createAt
              icon
              id
              isDeleted
              level
              name
              nameEn
              parentId
              shortName
              shortNameEn
              showIndex
              updateAt
            }
            createAt
            icon
            id
            isDeleted
            banners
            level
            name
            nameEn
            parentId
            shortName
            shortNameEn
            showIndex
            updateAt
          }
        }`;
      let opt = {
        query: categoriesGql,
        variables: {
          hostId: this.exhibitionId,
        },
      };
      return opt;
    },
    productOption() {
      let query = `
        query getProduct($query:QueryProductInput!) {
          getProduct(query:$query) {
            hasNextPage
            pageIndex
            items{
              category
              banner
              selling
              name
              id
              view3DUrl
              isPopularize
              panoramaUrl
              video
              exhibitor{
                isLiving
                company{
                  name
                }
              }
            }
          }
        }
      `;
      let ca = "";
      if (this.productActive && this.productActive !== "all") {
        ca = [this.productActive];
      }
      let opt = {
        query: query,
        variables: {
          query: {
            exhibitionId: this.exhibitionId,
            page: this.page,
            size: this.size,
            categories: ca,
          },
        },
      };
      return opt;
    },
  },
  created() {
    this.setUserProductHandle();
    this.productInit(); // 展品初始化
  },
  methods: {
    ...mapActions({
      graphqlPost: "graphqlPostByZXS",
      linkToProductDetais: "linkTo/linkToProductDetais",
    }),
    async productInit() {
      let res = await this.graphqlPost(this.productOption);
      let data = JSON.parse(JSON.stringify(res.data.getProduct));
      if (data) {
        data.items.forEach((e) => {
          if (e.category && e.category.indexOf("[") !== -1) {
            if (Array.isArray(JSON.parse(e.category))) {
              if (JSON.parse(e.category)[0]) {
                e.category = JSON.parse(e.category)[0].Value;
              } else {
                e.category = "";
              }
            } else {
              let cate = JSON.parse(e.category);
              e.category = cate.Value;
            }
          }
          if (e.banner && e.banner.indexOf("[") != -1) {
            let arr = JSON.parse(e.banner);
            e.banner = arr[0];
          }
        });
        this.productList = data.items;
      }
    },
    setProductActive(name) {
      this.productActive = name;
      this.productInit();
    },
    async setUserProductHandle() {
      let res = await this.graphqlPost(this.cateOption);
      let data = res.data.categories || [];
      if (data && data.length) {
        this.userProduct = [...new Set(data)];
        this.userProduct.length = 6;
      } else {
        this.productActive = "all";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@assets/scss/my-theme/mixin.scss";
.pro_wrap {
  .m-list2 {
    ::v-deep .ivu-row {
      .m-list2-box {
        .ml2-item-box {
          .play_video {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: rgba(0, 0, 0, 0.2);
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 3;
            i {
              font-size: 48px;
              color: #fff;
            }
          }
          border: none;
          img {
            width: 100%;
            height: 100%;
            display: block;
            object-fit: cover;
          }
        }
      }
    }
  }
}
.rebang {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 48px;
  height: 20px;
  @include background_color(#ff5e00);
  color: #fff;
  font-size: 12px;
}
.is_threeD {
  position: absolute;
  top: 0;
  right: 0px;
  width: 42px;
  height: 20px;
  background-color: #fff;
  border-radius: 0px 0px 0px 5px;
  border: solid 1px #ff5c00;
  display: flex;
  align-items: center;
}
.qj_div {
  position: absolute;
  right: 15px;
  bottom: 15px;
  background: rgba($color: #000, $alpha: 0.4);
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
  i {
    color: #fff;
    font-size: 24px;
  }
}
.m-list {
  min-width: 84%;
  max-width: 84%;
  padding-right: 8px;
  display: flex;
  justify-content: flex-end;
  height: 38px;
  align-items: center;
  &-l {
    flex: 1;
    width: calc(100% - 40px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 30px;
    display: flex;
    align-items: center;
  }
  &-r {
    width: 40px;
    text-align: right;
    cursor: pointer;
  }
  &-item {
    display: inline-block;
    margin-right: 10px;
    cursor: pointer;
    &.s-primary {
      color: #00a0e9;
    }
  }
}
</style>
