<template>
  <div class="m-card s-2" :class="{ 's-3': exhibitionId === '6d9aa6f0-aa0b-4523-9b16-6ee8307b628c' }" v-if="exhibitorList && exhibitorList.length">
    <div class="mc-top">
      <div class="mc-top-title">
        <span class="mc-top-txt s-bold s-primary">品牌展商</span>
      </div>
      <div class="mc-top-itembox">
        <div @click="$router.push(`/home/exhibitor/${exhibitionId}`)" class="mc-tip-item">更多</div>
      </div>
    </div>
    <div class="m-list2" style="padding: 20px 8px">
      <swiper class="swiper" :options="swiperOption" v-if="exhibitorList && exhibitorList.length">
        <swiper-slide v-for="(it, idx) in exhibitorList" :key="'splitArr-' + idx">
          <Row>
            <Col :key="'m-list2-1' + index" class="m-list2-box" span="4" v-for="(item, index) in it">
            <div @click="linkToExhibitorDetails(item.exhibitorId)" class="ml2-item">
              <div class="ml2-item-box">
                <img :src="item.logo" style="height: 100%" v-if="item.logo" v-load="'oLogoBig'" />
                <img class="logo" :src="exhibitionInfo.logo" v-else />
              </div>
            </div>
            <div class="ml2-item-info s-info2" style="text-align:center">
              <p :title="item.name">{{ item.shortName||item.name }}</p>
            </div>
            </Col>
          </Row>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination" v-if="exhibitorList.length > 1"></div>
      </swiper>
      <div class="u-empty" v-else>暂无数据</div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "brand-ex-module",
  data () {
    return {
      exhibitorList: [], // 品牌展商列表
      swiperOption: {
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        autoplay: true,
      }
    };
  },
  computed: {
    ...mapGetters({
      exhibitionId: "getExhibitionId",
      exhibitionInfo: "getExhibitionInfo",
    }),
    exhibitorOption () {
      let query = `#graphql
        query exhibitorTopByLogo($exhibitionId: ID!) {
          exList:exhibitorTopByLogo(input:{exhibitionId:$exhibitionId,num:24}) {
            exhibitorId
            logo
            name
            shortName
          }
        }
      `;
      let opt = {
        query: query,
        variables: {
          exhibitionId: this.exhibitionId,
        },
      };
      return opt;
    },
  },
  created () {
    this.exhibitorInit(); // 品牌列表初始化
  },
  methods: {
    ...mapActions({
      graphqlPost: "graphqlPostByZXS",
      linkToExhibitorDetails: "linkTo/linkToExhibitorDetails",
    }),
    async exhibitorInit () {
      let res = await this.graphqlPost(this.exhibitorOption);
      if (res.data && res.data.exList) {
        this.exhibitorList = this.splitArr(res.data.exList, 12);
      }
    },
    splitArr (list, splitNum) { // 一级数组按数量分割为二级数组
      if (!splitNum) return list;
      let newArr = [];
      for (let i = 0; i < list.length; i++) {
        newArr.push(list.slice(i, i += splitNum));
      }
      return newArr;
    }
  },
};
</script>

<style lang="scss" scoped>
.m-list2 {
  ::v-deep {
    .swiper-pagination-fraction,
    .swiper-pagination-custom,
    .swiper-container-horizontal > .swiper-pagination-bullets {
      bottom: -5px;
      z-index: 2;
    }
  }
}
</style>
