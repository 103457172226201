import { render, staticRenderFns } from "./people-module.vue?vue&type=template&id=5b5fc342&scoped=true&"
import script from "./people-module.vue?vue&type=script&lang=js&"
export * from "./people-module.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b5fc342",
  null
  
)

export default component.exports