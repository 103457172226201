<template>
  <div class="quick m-card" v-if="quickList && quickList.length">
    <div class="quick_wrap" :class="{ wrap5: exhibitionConfig && exhibitionConfig.display.customQuick.col == 5 }">
      <div class="quick_item" :class="{ list5: exhibitionConfig && exhibitionConfig.display.customQuick.col == 5 }" v-for="item in quickList" :key="item.id" @click="goUrl(item)">
        <img v-if="item.icon" :src="item.icon" />
        <img v-else src="@/assets/img/empty.png" />
        <p>{{ item.name }}</p>
      </div>
    </div>
    <showCode ref="showCode" :url="miniUrl"></showCode>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import showCode from "@components/showCode";

import url from "@/utils/url";
export default {
  name: "quick-module",
  data() {
    return {
      miniUrl: "",
      quickList: [], // 访客列表
    };
  },
  components: {
    showCode,
  },
  computed: {
    ...mapGetters({
      exhibitionConfig: "getExhibitionConfig",
    }),
    quickOption() {
      let query = `
        query($query:QueryInput!){
          quicklinkQuery{
            query(query:$query){
              items{
                icon
                name
                showIndex
                webUrl
                pcUrl
              }
            }
          }
        }
      `;
      let where = {
        Equal: {
          n: "itemId",
          v: this.exhibitionId,
        },
        EqualB: {
          n: "itemType",
          v: "exhibition",
        },
        Equal_lang: {
          n: "langVersion",
          v: "zh-cn",
        },
      };
      let order = [
        {
          N: "showIndex",
          v: 1,
        },
      ];
      let opt = {
        query: query,
        variables: {
          query: {
            page: 1,
            size: 30,
            where: JSON.stringify(where),
            order: JSON.stringify(order),
          },
        },
      };
      return opt;
    },
    exhibitionId() {
      return this.$route.params.id || "";
    },
  },
  created() {
    this.quickInit(); // 访客初始化
  },
  methods: {
    ...mapActions({
      graphqlPost: "graphqlPostByZXS",
      getShareConfig: "share/getShareConfig",
      getMapValue: "toolModule/getMapValue",
      getLiveRoomCode: "toolModule/getLiveRoomCode",
    }),
    async quickInit() {
      let data = await this.graphqlPost(this.quickOption);
      console.log(data, "ssss");
      let res = JSON.parse(JSON.stringify(data.data.quicklinkQuery.query.items));
      this.quickList = res;
    },
    goUrl(item) {
      let url = item.pcUrl;
      if (item.name === "大奖报名") {
        this.openMini({
          url: "/pages/vote/main?id=ec7c2d23-0a28-7575-f944-39f9ede54248",
        });
        return;
      }
      if (item.name === "精彩瞬间") {
        this.openMini({
          url: "/pages/headlines/headlinesLive/main?exhibitionId=6d9aa6f0-aa0b-4523-9b16-6ee8307b628c",
        });
        return;
      }
      let loc=window.location.host;
      if (url.indexOf(`${loc}`) != -1) {
        window.location.href = url;
      } else {
        window.open(url);
      }
    },
    async openMini(opt) {
      let key = await this.getMapValue({
        value: opt.url,
      });
      let option = {
        isPush: false,
        path: opt.path,
        scene: key.toString(),
      };
      console.log(option);
      let res = await this.getLiveRoomCode(option);
      this.miniUrl = res;
      this.$refs.showCode.open();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@assets/scss/my-theme/mixin.scss";
.quick {
  margin-bottom: 10px;
  .quick_wrap {
    width: 310px;
    box-sizing: border-box;
    padding: 0 0 25px;
    display: flex;
    flex-wrap: wrap;
    &.wrap5 {
      padding: 0 5px 25px;
    }
  }
  .quick_item {
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 25px;
    &.list5 {
      width: 20%;
    }
    cursor: pointer;
    &:hover {
      p {
        @include font_color(#1890ff);
      }
    }
    img {
      width: 38px;
      height: 38px;
      display: block;
      border-radius: 50%;
      object-fit: cover;
    }
    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 12px;
      color: #666;
      margin-top: 4px;
    }
  }
}
</style>
