<template>
  <div class="m-card s-2" :class="{ 's-3': exhibitionId === '6d9aa6f0-aa0b-4523-9b16-6ee8307b628c' }" style="height: 374px">
    <div class="mc-top">
      <div class="mc-top-title">
        <span class="mc-top-txt s-bold s-primary">资讯头条</span>
      </div>
      <div class="mc-top-itembox">
        <div @click="$router.push(`/home/news/${exhibitionId}`)" class="mc-tip-item">
          更多
        </div>
      </div>
    </div>
    <div style="min-height: 190px" v-if="newsList && newsList.length">
      <template v-for="(item, index) in newsList">
        <div :key="'news-1-' + index" @click="linkToNewsDetails(item.id)" v-if="!item.externalUrl">
          <div class="m-box" v-if="index === 0">
            <div class="mb-left">
              <div :title="item.title" class="mb-title">{{ item.title }}</div>
              <p class="mb-txt">{{ item.content }}</p>
              <p class="mb-txt2">{{ item.createAt | moment("YYYY/MM/DD") }}</p>
            </div>
            <div class="mb-right">
              <img :src="item.covers[0]" v-if="item.covers && item.covers.length" v-load="'oNews'" />
              <img src="@/assets/img/empty.png" v-else />
            </div>
          </div>
          <div class="m-list theme-1" v-else>
            <div class="ml-item">
              <div class="ml-item-l">{{ item.title }}</div>
              <div class="ml-item-r">
                <p class="mb-txt2">
                  {{ item.createAt | moment("YYYY/MM/DD") }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div :key="'news-1-' + index" @click="goexternalUrl(item)" v-else>
          <div class="m-box" v-if="index === 0">
            <div class="mb-left">
              <div :title="item.title" class="mb-title">{{ item.title }}</div>
              <p class="mb-txt">{{ item.content }}</p>
              <p class="mb-txt2">{{ item.createAt | moment("YYYY/MM/DD") }}</p>
            </div>
            <div class="mb-right">
              <img :src="item.covers[0]" v-if="item.covers && item.covers.length" v-load="'oNews'" />
              <img src="@/assets/img/empty.png" v-else />
            </div>
          </div>
          <div class="m-list theme-1" v-else>
            <div class="ml-item">
              <div class="ml-item-l">{{ item.title }}</div>
              <div class="ml-item-r">
                <p class="mb-txt2">
                  {{ item.createAt | moment("YYYY/MM/DD") }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
    <!-- <div class="u-empty" v-else>暂无数据</div> -->
    <div class="u-empty" v-if="loading">数据加载中...</div>
    <div class="u-empty" v-else-if="!loading && !newsList.length">暂无数据</div>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "news-module",
  data() {
    return {
      newsList: [], // 资讯列表
      loading: true,
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      exhibitionId: "getExhibitionId",
    }),

    newsOption() {
      let queryStr = `
      query ($query:_Any!) {
          articleQuery {
            page(query:$query) {
              hasNextPage
              pageIndex
              totalCount
              items {
                articleType
                author
                authorId
                commentCount
                content
                covers
                createAt
                externalUrl
                favoriteCount
                id
                introduction
                itemId
                keywords
                likeCount
                metadata
                publishAt
                shareCount
                status
                subtitle
                title
                updateAt
                userCount
                viewCount
              }
            }
          }
        } 
      `;
      let where = {
        equal_itemId: {
          n: "itemId",
          v: this.exhibitionId,
        },
        or: {
          // equal_authorType: { n: "authorType", v: "exhibition" },
          greaterThan_topic: { n: "topic", v: 0 },
        },
      };
      let order = [
        { N: "topic", v: 0 },
        { N: "toppingIndex", v: 0 },
        { N: "publishAt", v: 0 },
      ];
      let opt = {
        query: queryStr,
        variables: {
          query: {
            page: 1,
            size: 5,
            where: where,
            order: order,
          },
        },
      };
      return opt;
    },
  },
  created() {
    this.newsInit(); // 資訊初始化
  },
  methods: {
    ...mapActions({
      graphqlPost: "graphqlPostByZXS",
      linkToNewsDetails: "linkTo/linkToNewsDetails",
    }),
    async newsInit() {
      let data = await this.graphqlPost(this.newsOption);
      let res = JSON.parse(JSON.stringify(data.data.articleQuery.page));
      if (res && res.items.length) {
        res.items.forEach((e) => {
          if (e.covers && e.covers.indexOf("[") != -1) {
            e.covers = JSON.parse(e.covers);
          } else {
            e.covers = [];
          }
          e.publishAt = moment(e.publishAt).format("YYYY/MM/DD HH:mm:ss");
        });
      }
      this.newsList = res.items;
      this.loading = false;
      this.$emit("setCount", res.totalCount); 
    },
    goexternalUrl(item) {
      window.open(item.externalUrl);
    },
  },
};
</script>

<style lang="scss" scoped>
.m-box {
  padding: 12px 20px;
}
.m-list .ml-item {
  padding: 10px 0 12px;
}
.mb-txt2 {
  font-size: 12px;
  color: #999;
}
.ml-item {
  font-size: 14px;
  color: #333;
}
</style>
