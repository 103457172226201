<template>
  <div class="m-card s-2" :class="{ 's-3': exhibitionId === '6d9aa6f0-aa0b-4523-9b16-6ee8307b628c' }" v-if="cateList && cateList.length">
    <div class="mc-top">
      <div class="mc-top-title">
        <span class="mc-top-txt s-bold s-primary">展品类别</span>
      </div>
    </div>
    <div class="list" :class="isMore ? 'moreList' : ''" ref="moreList">
      <div class="content" ref="moreList" v-if="cateList && cateList.length">
        <div class="list-item" v-for="(item, index) in cateList" :key="'ca-' + index">
          <div class="title" @click="setCategories(item)">
            {{ item.name }}
          </div>
          <!-- <template v-if="item.childrens">
            <span class="brand"
              v-for="(c, idx) in item.childrens"
              :key="'ca-child-' + index + idx"
              @click="setCategories(c)">{{ c.name }}</span>
          </template> -->
        </div>
      </div>
      <div class="top-more" @click.stop="topMore" v-if="isMore">
        <Icon type="ios-arrow-down" color="#c7c7c7" />
      </div>
      <div v-else-if="!cateList.length" class="u-empty">
        暂无数据
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
export default {
  name: "top-link",
  data() {
    return {
      show: false,
      cateList: [],
      isMore: false,
    };
  },
  computed: {
    ...mapGetters({
      selectCategories: "getSelectCategories",
      exhibitionId: "getExhibitionId",
      exhibitionInfo: "getExhibitionInfo",
    }),
    path() {
      return this.$route.path;
    },
  },
  created() {
    this.init();
  },
  mounted() {
    // this.$nextTick(() => {
    //   let height = this.$refs.moreList.offsetHeight;
    //   console.log(this.$refs.moreList, height, "moreList");
    // });
  },
  methods: {
    ...mapMutations({
      setSelectCategories: "setSelectCategories",
    }),
    ...mapActions({
      graphqlPost: "graphqlPostByZXS",
    }),
    tap() {
      this.show = !this.show;
      this.$nextTick(() => {
        let height = this.$refs.moreList.offsetHeight;
        if (height > 476) {
          this.isMore = true;
        } else {
          this.isMore = false;
        }
      });
    },
    topMore() {
      this.isMore = false;
    },
    //获取数组  传一个数组json 遍历其下 id 于 name isLists 是否获取其list
    getList(arrJson) {
      let classArr = [];
      arrJson.forEach((el) => {
        classArr.push({
          id: el.id,
          name: el.name,
        });
      });
      return classArr;
    },
    getRegionList(arrJson, isLists = true) {
      let classArr = [];
      if (isLists) {
        arrJson.forEach((el) => {
          classArr.push({
            id: el.id,
            name: el.name,
            expand: true,
            children: this.getList(el.lists),
          });
        });
      } else {
        arrJson.forEach((el) => {
          classArr.push({
            id: el.id,
            name: el.name,
            expand: true,
          });
        });
      }
      return classArr;
    },
    async init() {
      let categoriesGql = `
          query($hostId:Guid!){
            categories(hostId:$hostId){
              childrens{
                  createAt
                  icon
                  id
                  isDeleted
                  level
                  name
                  nameEn
                  parentId
                  shortName
                  shortNameEn
                  showIndex
                  updateAt
              }
              createAt
              icon
              id
              isDeleted
              banners
              level
              name
              nameEn
              parentId
              shortName
              shortNameEn
              showIndex
              updateAt
            }
          }`;
      let opt = {
        query: categoriesGql,
        variables: {
          hostId: this.exhibitionId,
        },
      };
      let res = await this.graphqlPost(opt);
      let data = res.data.categories;
      this.cateList = data;
    },
    setCategories(e) {
      this.setSelectCategories(e);
      this.show = false;
      this.$router.push(`/home/product/${this.exhibitionId}`);
    },
  },
};
</script>

<style scoped lang="scss">
@import "@assets/scss/my-theme/mixin.scss";
.top-more {
  width: 100%;
  height: 17px;
  background-color: #e7f4ff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #4d4d4d;
  position: absolute;
  left: 0;
  bottom: 0;
  cursor: pointer;
}
.list {
  background-color: #fff;
  position: relative;
  .content {
    padding: 18px 20px 2px 20px;
  }
  .list-item {
    margin-bottom: 9px;
    cursor: pointer;

    .title {
      font-size: 14px;
      color: #333;
      font-weight: bold;
      margin-bottom: 12px;
      &:hover {
        @include font_color(#1890ff);
      }
    }
    .brand {
      font-size: 12px;
      color: #666;
      display: inline-block;
      margin-right: 20px;
      margin-bottom: 12px;
      &:hover {
        @include font_color(#1890ff);
      }
    }
  }
}
.moreList {
  max-height: 477px;
  overflow: hidden;
}
.top-link {
  font-size: 16px;
  display: flex;
  width: 1200px;
  margin: 0 auto;
  .left {
    position: relative;
    .item {
      width: 160px;
      height: 42px;
      margin-right: 5px;
      @include background_color(#1890ff);
      color: #fff;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px 5px 0px 0px;
      cursor: pointer;
      img {
        padding-right: 10px;
      }
    }
  }
  .right {
    .item {
      color: #333;
      line-height: 42px;
      display: inline-block;
      padding: 0 43px;
      cursor: pointer;
      border-radius: 5px 5px 0px 0px;
      transition: all 0.3s;
      &.active {
        color: #fff !important;
        @include background_color(#1890ff);
      }
      &:hover {
        @include font_color(#1890ff);
      }
    }
  }
}
</style>
