<template>
  <div class="live-module" v-if="bannerList && bannerList.length">
    <div class="lm-l">
      <div :key="'list' + index" @click="linkTo(item)" class="lm-l-item" v-for="(item, index) in bannerList">
        <img :src="item.covers" v-if="item.covers" />
        <img alt src="@/assets/img/xwyd.png" v-else />
      </div>
    </div>
    <div class="lm-r">
      <div @click="$router.push(`/home/about/${exhibitionId}`)" class="lm-r-item">
        <div class="item">
          <i class="icon iconfont icon-gaikuang" style="color: #5aaae5"></i>
          <p>展会概况</p>
        </div>
      </div>
      <!-- <div @click="golinkToRegSteps" class="lm-r-item"> -->
      <div  class="lm-r-item" style='background: #e5e5e5;
            color: #666;'>
            <!-- @click="linkToRegStepsHandle" -->
       <Tooltip content="暂未开放" placement="bottom">
        <div class="item">
          <i class="icon iconfont icon-reg" style="color: #f2a234"></i>
          <p>注册参观</p>
        </div>
           </Tooltip>
      </div>

      
      <div class="lm-r-item">
        <a :href="exhibitionInfo.website" class="item" target="_blank" v-if="exhibitionInfo && exhibitionInfo.website">
          <i class="icon iconfont icon-demo17" style="color: #eb675e"></i>
          <p>展会官网</p>
        </a>
        <Tooltip content="暂未开放" placement="bottom" style="height: 100%; display: flex" v-else>
          <div class="item no-info">
            <i class="icon iconfont icon-demo17" style="color: #666"></i>
            <p>展会官网</p>
          </div>
        </Tooltip>
      </div>
      <div class="lm-r-item">
        <div class="item" v-if="enabledBmm" @click="linkToTour">
          <i class="icon iconfont icon-tudi-xianxing-cu" style="color: #b86df2"></i>
          <p>展位导览</p>
        </div>
        <Tooltip content="暂未开放" placement="bottom" style="height: 100%; display: flex" v-else>
          <div class="item no-info">
            <i class="icon iconfont icon-tudi-xianxing-cu" style="color: #666"></i>
            <p>展位导览</p>
          </div>
        </Tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
export default {
  name: "live-module",
  data () {
    return {
      bannerType: "show.page.home.ad.pc", // 首页广告

      bannerList: null, // bannner列表
      HGexhibitionList: [
        "63af2701-17c3-4fb1-b826-aca9228adcc3",
        "3bc3ed3d-d84e-40d6-be92-910905ba0576",
        "5fe797f0-a87e-46a6-ae72-48205eda9086",
        "68da03a5-8127-43b0-903c-97ccb982c429",
        "33ffaaa0-6c0d-4878-b96e-d22573d3b038",
      ],
      isHG: false,
    };
  },
  computed: {
    ...mapGetters({
      exhibitionId: "getExhibitionId",
      exhibitionInfo: "getExhibitionInfo",
      exhibitionConfig: "getExhibitionConfig",
      enabledBmm: "getEnabledBmm",
    }),
    pcRegLink () {
      return (
        this.exhibitionConfig &&
        this.exhibitionConfig.visitorReg &&
        this.exhibitionConfig.visitorReg.pcRegLink
      );
    },
    
  },
  created () {
    this.bannerInit();
  },
  methods: {
    ...mapActions({
      linkToTour: "linkTo/linkToTour",
      linkToRegSteps: "linkTo/linkToRegSteps",
      getBannerByGraphal: "banner/getBannerByGraphal",
    }),
    async bannerInit () {
      this.bannerList = [];
      let listData = await this.getBannerByGraphal(this.bannerType);
      if (listData.bannerQuery && listData.bannerQuery.by) {
        listData.bannerQuery.by.sort((a, b) => {
          return a.showIndex - b.showIndex;
        });
        this.bannerList = listData.bannerQuery.by;
      }
      if (this.bannerList.length > 3) {
        this.bannerList = this.bannerList.slice(0, 3);
      }
      if (this.bannerList.length > 0) {
        let n = Math.abs(this.bannerList.length - 3);
        for (let i = 0; i < n; i++) {
          this.bannerList.push({ covers: false });
        }
      }
    },

    linkToRegStepsHandle() {
      if (this.pcRegLink) {
        window.open(this.pcRegLink)
      }else {
        this.linkToRegSteps();
      }
    },

    golinkToRegSteps () {
      // if (this.exhibitionId == "63af2701-17c3-4fb1-b826-aca9228adcc3") {
      //   window.open(
      //     "http://demo.eastfair.com/FShow/page/login.html?ExhID=1013&InviteType=Platform&InviteID=0&InviteCode=FShow"
      //   );
      //   return;
      // } 
      this.HGexhibitionList.forEach((c) => {
        if (c.indexOf(this.exhibitionId) != -1) {
          this.isHG = true;
          window.open("http://www.cacshow.com/index.php?s=/Home/Article/index/category/gzydj");
        }
      });
      if (!this.isHG) {
        this.linkToRegSteps();
      }
    },
    linkTo (e) {
      if (e && e.url) {
        window.open(e.url);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@assets/scss/my-theme/mixin.scss";
.live-module {
  min-height: 185px;
  display: flex;
  margin: 20px 0;
  position: relative;
  z-index: 2;
  .lm {
    &-l {
      flex: 1;
      display: flex;
      justify-content: space-between;
      &-item {
        width: 328px;
        height: 185px;
        background: #ccc;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    &-r {
      width: 185px;
      height: 185px;
      margin-left: 10px;
      background-color: #fff;
      border: solid 1px #ededed;
      display: flex;
      flex-wrap: wrap;
      border-radius: 5px;
      &-item {
        width: 50%;
        height: 50%;
        display: inline-block;

        &:nth-child(1) {
          border-right: solid 1px #ededed;
          border-bottom: solid 1px #ededed;
        }
        &:nth-child(2) {
          border-bottom: solid 1px #ededed;
        }
        &:nth-child(3) {
          border-right: solid 1px #ededed;
        }
        .item {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-wrap: wrap;
          text-align: center;
          height: 100%;
          padding: 12px;
          cursor: pointer;
          &:hover {
            @include background_color_hover(#1890ff);
            @include font_color(#1890ff);
            p {
              @include font_color(#1890ff);
            }
          }

          .iconfont {
            font-size: 28px;
          }
          p {
            width: 100%;
            color: #333;
          }
        }
        .no-info {
          background: #e5e5e5;
          color: #666;
          &:hover {
            background: #e5e5e5 !important;
            color: #666 !important;
            p {
              color: #666 !important;
            }
          }
        }
      }
    }
  }
}
</style>
