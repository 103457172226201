<template>
  <iModal v-model="show" :mask-closable="false" @on-cancel="cancel" footer-hide width="400" class-name="my-show">
    <img :src="url" class="code" />
    <p class="tip">
      详细信息请使用微信扫描二维码<br>进入小程序内查看
    </p>
  </iModal>
</template>
<script>
import QRCode from "qrcodejs2";
import { mapGetters } from "vuex";
import config from "@assets/config.json"; // api 请求json

export default {
  name: "showCode",
  props: {
    url: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    open() {
      this.show = true;
    },
    cancel() {
      this.show = false;
    },
  },
};
</script>
<style lang="scss">
.my-show {
  text-align: center;
  .code {
    width: 180px;
    height: 180px;
    
  }
  .tip {
    font-size: 18px;
    color: #333;
    font-weight: 600;
    margin-bottom: 20px;
    text-align: center;
    margin-top: 10px;
  }
}
</style>
